import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "reactstrap";
import "../../../scss/AddCouponModal.scss";
import { getBatchList } from "../../../../../helpers/mallbackend_helper";
import {
  ConfigProvider,
  InputNumber,
  Pagination,
  PaginationProps,
  Popconfirm,
  Tabs,
} from "antd";
import Table, { ColumnsType } from "antd/es/table";
import locale from "antd/locale/zh_CN";
import moment from "moment";
import EditTableCom from "../Com/EditTableCom";
import { toast } from "react-toastify";
// 添加优惠券
const AddCouponModal = (props: any) => {
  const { isOpen, toggle, onOk, selectedList, categoryData, curItem } = props;
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  // const [categoryData, setCategoryData] = useState<any>([]); //卡券类型列表
  const [batchData, setBatchData] = useState<any>([]); //卡券列表
  const [selectedRowList, setSelectedRowList] = useState<any[]>([]); //选中卡券id
  const [checkedList, setCheckedList] = useState<any[]>([]); //用户预选卡券list
  const [dataList, setDataList] = useState<any[]>([]);//用户已选

  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const toggle2 = () => {
    setOpen(false);
  };
  //模态框属性
  const modalOpts = {
    size: "xl", //ml,sm,lg,xl
    role: "dialog",
    isOpen: isOpen,
    centered: true,
    autoFocus: true,
    toggle: toggle,
    className: "add-coupon-modal checkedModal",
  };
  const modalOpts2 = {
    size: "xl", //ml,sm,lg,xl
    role: "dialog",
    isOpen: open,
    centered: true,
    autoFocus: true,
    toggle: toggle2,
    className: "add-coupon-modal",
  };
  const onToggle = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  interface DataType {
    batch_id: string; //批次标识
    stock: number; //库存
    residue_stock: number; //剩余库存
    number: any;
    name: string; //批次名称
    limit_content: string; //限制要求
    description: string; //描述
    begin_number: number; //开始编号
    end_number: number; //结束编号
    type: number; //类型
    value: number; //面额
    min_consume_value: number; //最低消费面额，例如：消费满1000抵扣20，如果0，则无限制
    valid_begin_at: string; //有效开始日期
    valid_end_at: string; //有效结束日期
    couponmallcategory: any; //对应商场类别
    category: any; //对应商品类别
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
      width: 300,
    },
    {
      title: "面额",
      dataIndex: "limitValue",
      key: "limitValue",
      width: 100,
    },
    {
      title: "库存",
      dataIndex: "residue_stock",
      key: "residue_stock",
      width: 100,
      render: (count: number) => {
        if (count > 0) {
          return (
            <span style={{ color: "green", fontWeight: "bold" }}>{count}</span>
          );
        } else
          return (
            <span style={{ color: "red", fontWeight: "bold" }}>{count}</span>
          );
      },
    },
    {
      title: "可用商场",
      key: "couponmallcategory",
      dataIndex: "couponmallcategory",
      width: 200,
      render: (value) => {
        if (value?.length > 0) {
          return value
            ?.map((item: { category: { name: any } }) => item.category.name)
            ?.join(",");
        }
      },
    },
    {
      title: "有效开始日期",
      dataIndex: "valid_begin_at",
      key: "valid_begin_at",
      width: 250,
      render: (text) => {
        return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "";
      },
    },
    {
      title: "有效结束日期",
      dataIndex: "valid_end_at",
      key: "valid_end_at",
      width: 250,
      render: (text) => {
        return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "";
      },
    },
    {
      title: "限制要求",
      dataIndex: "limit_content",
      key: "limit_content",
      width: 150,
    },
  ];
  const option = {
    page_size: 5, // ，每页条数
    page_number: 1, //页码
    total: 0, //总数
  };
  const [pageOptions, setPageOptions] = useState(option);
  const showTotal: PaginationProps["showTotal"] = (total: number) =>
    `每页 ${pageOptions.page_size} 条，共 ${total} 条`;
  //分页更改
  const onPageChange = (sizePerPage: number, page: number) => {
    getBatchData(activeTab, sizePerPage);
  };
  //获取卡券列表
  const getBatchData = async (type: number, page?: number) => {
    const params = {
      page_number: page || pageOptions.page_number,
      page_size: pageOptions.page_size,
      type: type || activeTab,
      valid: true,
    };
    const res = await getBatchList(params);
    if (res.code === 200) {
      setBatchData(res.data);
      setPageOptions({ ...option, total: res.total });
    }
  };
  //表格复选框
  const rowSelection = {
    //用户手动选择/取消选择某行的回调
    onSelect: (
      record: any,
      selected: any,
      selectedRows: any,
      nativeEvent: any
    ) => {
      if (!selected) {
        const list = selectedRowList.filter(
          (e) => e.batch_id !== record.batch_id
        );
        setSelectedRowList(list);
        const list2 = checkedList.filter((e) => e.batch_id !== record.batch_id);
        setCheckedList(list2);
      }
    },
    //用户手动选择/取消选择所有行的回调
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      if (!selected) {
        //取消选择所有行
        const ids = changeRows?.map((e: any) => e.batch_id);
        const list = checkedList.filter((e) => !ids.includes(e.batch_id));
        setCheckedList(list);
      }
    },
    //选中项发生变化时的回调
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      const items: any = [];
      const list = selectedRows;
      const newList = list?.map((item: any) => {
        const { value, ...rest } = item;
        return { limitValue: value, ...rest };
      });
      const rowList: any = [];
      newList.forEach((row) => {
        if (row.residue_stock <= 0) {
          toast.error(`【${row.name}】当前库存不足，无法选择！`);
          return false;
        }
        const isChecked = selectedRowList.some(
          (selectedRow: any) => selectedRow.batch_id === row.batch_id
        );
        const isChecked2 = checkedList.some(
          (selectedRow: any) => selectedRow.batch_id === row.batch_id
        );
        row.count = 1;
        //判断当前选择的项是否已存在checkedList中，不存在就添加到selectedRowList中
        if (!isChecked && !isChecked2) {
          rowList.push(row);
        }
      });
      // console.log(rowList);
      setCheckedList([...checkedList, ...rowList]);
      setSelectedRowList((prevSelectedRowList: any) => [
        ...prevSelectedRowList,
        ...rowList,
      ]);
      let exists = selectedRowKeys;
      const filterRes = newList.filter((e) => e.residue_stock <= 0);
      if (filterRes.length > 0) {
        const mapRes = filterRes.map((e) => e.batch_id);
        exists = selectedRowKeys.filter((item) => !mapRes.includes(item));
      }
      setSelectedRowKeys(exists);
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };
  //点击tab
  const handleTabClick = (e: any) => {
    setActiveTab(e);
    getBatchData(e, 1);
  };
  //用户已选卡券弹窗submit
  const handleSubmit = () => {
    onOk(checkedList);
  };
  //用户添加卡券弹窗submit
  const handleCheckedSubmit = () => {
    setDataList(checkedList);
    setOpen(false);
  };
  const handleAdd = () => {
    setOpen(true);
    setActiveTab(categoryData[0]?.category_id);
    getBatchData(categoryData[0]?.category_id);
  };
  useEffect(() => {
    if (selectedList && selectedList?.length > 0) {
      const { type, name, value } = selectedList[0];
      if (
        selectedList?.length === 1 &&
        type === "coupon" &&
        name === "优惠券" &&
        value === null
      ) {
        return;
      }
      setDataList(selectedList);
      setCheckedList(selectedList);
    }
  }, [selectedList]);
  useEffect(() => {
    if (checkedList.length > 0) {
      const ids = checkedList.map((e) => e.batch_id);
      setSelectedRowKeys(ids);
      setSelectedRowList(checkedList);
      // setDataList(checkedList);
    } else {
      setSelectedRowKeys([]);
      setSelectedRowList([]);
      // setDataList([]);
    }
  }, [checkedList]);
  let editTableProps: any = {
    columns,
    dataList,
    setDataList,
  };
  useEffect(()=>{
    setCheckedList(dataList);
  },[dataList])
  return (
    <React.Fragment>
      <>
        <Modal backdrop="static" {...modalOpts} toggle={toggle}>
          <div className="modal-header">
            已选优惠券列表
            <button
              type="button"
              onClick={toggle}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row className="row-box">
              <div className="col-lg-10">
                <span style={{ color: "red" }}>*</span>
                只可添加在发放渠道范围内、且在有效期内的优惠前。在活动中或有效期在活动开始前的优惠券，将无法参与发放
              </div>
              <div className="col-lg-2">
                <Button
                  type="button"
                  style={{
                    backgroundColor: "#8558FA",
                    position: "relative",
                    top: "-5px",
                    left: "40px",
                  }}
                  onClick={() => {
                    handleAdd();
                  }}
                >
                  添加优惠券
                </Button>
              </div>
            </Row>
            <div className="div-nav-box">
              <div className="table-responsive">
                <ConfigProvider locale={locale}>
                  <EditTableCom {...editTableProps}></EditTableCom>
                  {/* <Table
                    style={{ height: "500px" }}
                    columns={columns}
                    dataSource={checkedList}
                    pagination={false}
                    rowKey={(record) => record.batch_id}
                  /> */}
                </ConfigProvider>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-light waves-effect btn-cancel"
              onClick={toggle}
            >
              取消
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-primary waves-effect btn-save"
            >
              确认
            </button>
          </div>
        </Modal>
        <Modal backdrop="static" {...modalOpts2} toggle={toggle2}>
          <div className="modal-header">
            添加优惠券
            <button
              type="button"
              onClick={toggle2}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Row className="row-box">
              <div className="col-lg-12">
                <span style={{ color: "red" }}>*</span>
                只可添加在发放渠道范围内、且在有效期内的优惠前。在活动中或有效期在活动开始前的优惠券，将无法参与发放
              </div>
            </Row>
            <div className="div-nav-box">
              <Tabs
                defaultActiveKey="1"
                type="card"
                size="middle"
                onChange={(e) => {
                  handleTabClick(e);
                }}
                items={categoryData?.map((item: any, index: number) => {
                  return {
                    label: item.name,
                    key: item.category_id,
                  };
                })}
              />
              <div className="table-responsive">
                <ConfigProvider locale={locale}>
                  <Table
                    rowSelection={{
                      type: "checkbox",
                      selectedRowKeys: selectedRowKeys,
                      ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={batchData}
                    pagination={false}
                    rowKey={(record) => record.batch_id}
                  />
                  <div style={{ height: "50px", marginTop: "15px" }}>
                    <Pagination
                      showQuickJumper
                      defaultPageSize={pageOptions.page_size}
                      showTotal={showTotal}
                      total={pageOptions.total}
                      onChange={onPageChange}
                      defaultCurrent={1}
                    />
                  </div>
                </ConfigProvider>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-light waves-effect btn-cancel"
              onClick={toggle2}
            >
              取消
            </button>
            <button
              type="button"
              onClick={handleCheckedSubmit}
              className="btn btn-primary waves-effect btn-save"
            >
              确认
            </button>
          </div>
        </Modal>
      </>
    </React.Fragment>
  );
};
export default AddCouponModal;
