import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";

//scss
import "../../scss/AddCustomSchedule.scss";

import LaunchPlan from "./LaunchPlan/index"; //启动计划
import IssuanceOfEquity from "./IssuanceOfEquity/index"; //发放权益
import SendMessage from "./SendMessage/index"; //发送消息

import { AvForm, AvField } from "availity-reactstrap-validation";
//添加任务com
import IssuanceOfEquityStep from "./IssuanceOfEquity/IssuanceOfEquityStep";
import SendMessageStep from "./SendMessage/SendMessageStep";
import AddTaskCom from "./Com/AddTaskCom";
import { useHistory, useLocation } from "react-router-dom";
import {
  postMarketingPlans,
  getMarketingPlansById,
  putMarketingPlans,
} from "../../../../helpers/mallbackend_helper";
import { nanoid } from "nanoid";
import moment from "moment";
import _ from "lodash";

const AddCustomSchedule = () => {
  interface Content {
    tasks: [
      {
        type: string;
        name: string;
        value: string;
      }
    ];
  }
  interface Tasks {
    exec_type: string;
    task_name: string;
    task_type: string;
    delay_exec_period: string;
    delay_exec_period_unit: string;
    delay_exec_time: string;
    exec_content: Content;
    sort_order: number;
  }
  interface LocalState {
    id: string;
    type: string;
  }
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let id = searchParams.get("id") || "";
  const type = searchParams.get("type") || "";

  const [pageType, setPageType] = useState(type);
  const [isDisabled, setIsDisabled] = useState(type === "info" ? true : false);
  const [step, setStep] = useState("startPlan"); //当前选中计划
  const [curId, setCurId] = useState(""); //当前选中步骤id
  const [tasks, setTasks] = useState<any>([
    {
      index: 1,
      id: "planStart",
      type: "start",
      taskContent: {},
    },
    {
      index: 2,
      id: "planEnd",
      type: "end",
      taskContent: {},
    },
  ]); //任务步骤list
  const [items, setItems] = useState<any>({}); //商品列表
  const [inputPlan, setInputPlan] = useState("自定义营销计划"); //标题名
  const [isEdit, setIsEdit] = useState(false); //是否修改标题
  let [marketing, setMarketing] = useState({
    business_type: "mall", //业务类型
    business_id: "marketing", //业务标识
    name: inputPlan, //名称
    type: "once", //once：单次营销、customer_behavior：客户行为触发、repeat：定期重复
    status: "started", //draft:草稿，started：未开始
    plan_is_forever: true, //计划是否长期有效
    effective_time: null, //计划生效时间
    expire_time: null, //计划过期时间
    trigger_frequency: null, //触发频率，daily: 每天，weekly：每周，monthly：每月，yearly：每年，当类型为单次营销或客户行为触发时，此字段应为null
    trigger_time: null, //触发时间，当类型为客户行为触发时，此字段为null，当类型为单次营销时此字段存储触发的日期和时间（如：2023-10-10 18:00:00），当类型为定期重复时，此字段存储触发时间（如：09:00）
    trigger_cron: null, //触发的cron表达式，当类型为定期重复时，此字段存储触发的cron表达式，其他类型时此字段为null
    trigger_behavior_type: "buy_goods",
    trigger_rules: {
      rules: [], //商品信息
    }, //触发规则，当类型为客户行为触发时，此字段有值，否则为null
    limit_type: "once", //参与次数限制类型，once：仅限1次，unlimited：不限次数，custom：同一用户limit_count_period周期内最多参加limit_max_times次
    limit_count_period: 0, //参与次数的计数周期，如1天或1周
    limit_count_period_unit: null, //参与次数的计数周期单位，day：天，week：周，month：月，year：年
    limit_max_times: 1, //参与次数的计数周期，如1天或1周
    target_is_all_customer: true, //目标客户是否为全部客户
    target_customers: null, //目标客户列表
    is_template: false, //是否为模板
    tasks: [], //营销计划任务列表
    description: "",
    plan_termination_type: "terminate", //计划终止类型，terminate：直接针对客户种植计划 skip：该客户跳到下一个任务继续执行
  });
  let content: Content = {
    tasks: [
      {
        type: "point",
        name: "积分",
        value: "3",
      },
    ],
  };
  const updateParamsData = (newData: any) => {
    setMarketing((e) => ({
      ...e,
      ...newData,
    }));
  };
  //点击任务盒子
  function onHandlePlanClick(step: any, id?: string) {
    setStep(step);
    if (id) setCurId(id);
  }
  const onHandleCallBack = () => {
    history.push("/marketingList");
  };
  //添加任务
  const onHandleAddTask = (type: string, id: string) => {
    let typeName = type === "benefits" ? "发放权益" : "发送消息";
    //发放权益task
    let addTasks: Tasks = {
      exec_type: "immediately", // 执行类型，immediately：上一任务完成后立即执行，delay：延时
      task_name: typeName,
      task_type: type, // 类型，benefits：发送权益，message：发送消息，follow_up_task：回访任务
      delay_exec_period: "1",
      delay_exec_period_unit: "day",
      delay_exec_time: "00:00",
      exec_content: content,
      sort_order: 0,
    };
    //发送消息task
    if (type === "message") {
      content.tasks[0].type = "send_sms";
      content.tasks[0].name = "发送短信";
      content.tasks[0].value = "";
      addTasks = {
        ...addTasks,
        exec_content: content,
      };
    }
    const index = nanoid();
    const newAttrs = {
      index: index,
      id: `plan${index}`,
      type,
      taskContent: addTasks,
    };

    const startTaskIndex = tasks.findIndex((item: any) => item.id === id);
    const newTasks = [...tasks];
    newTasks.splice(startTaskIndex + 1, 0, newAttrs);
    setTasks(newTasks);
  };
  const addTaskComProps = {
    isDisabled: isDisabled,
    onHandleAddTask: onHandleAddTask,
  };
  //移除
  const onHandleRemove = async (id: string) => {
    const newTasks = tasks.filter((item: any) => item.id !== id);
    setTasks(newTasks);
    setStep("startPlan");
  };
  //发布
  const handleRelease = async (type: string) => {
    let submitType = pageType;
    const paramsData: any = marketing;
    const value = marketing.type;
    if (paramsData.tasks?.length > 0) {
      const tasks = [];
      for (const [index, item] of paramsData.tasks.entries()) {
        const curItem = {
          ...item,
          sort_order: index,
        };
        tasks.push(curItem);
      }
      paramsData.tasks = tasks;
    }
    if (value === "once") {
      paramsData.limit_type = null;
      paramsData.limit_count_period = null;
      paramsData.limit_count_period_unit = null;
      paramsData.plan_is_forever = true;
    }
    if (value === "target_customers") {
      paramsData.trigger_time = null;
    }
    if (value === "once" || value === "target_customers") {
      paramsData.trigger_frequency = null;
      paramsData.trigger_cron = null;
    }
    if (value === "once" || value === "repeat") {
      //单次营销 || 定期重复
      paramsData.trigger_behavior_type = null;
      paramsData.trigger_rules = null;
    }
    if (paramsData.effective_time)
      paramsData.effective_time = moment(paramsData.effective_time).format(
        "YYYY-MM-DD 00:00:00"
      );
    if (paramsData.expire_time)
      paramsData.expire_time = moment(paramsData.expire_time).format(
        "YYYY-MM-DD 23:59:59"
      );
    //如果目标客户为全部用户，则清空目标客户列表
    if (paramsData.target_is_all_customer) paramsData.target_customers = null;
    if (paramsData.target_customers && paramsData.target_customers.length > 0) {
      paramsData.target_customers.forEach(
        (customer: { compare_value_cn: any }) =>
          delete customer.compare_value_cn
      );
    }
    let isMessage = false;
    if (paramsData.tasks.length > 0) {
      _.forEach(paramsData.tasks, (item, index) => {
        if (item.task_type === "message") {
          const messageTasks = item.exec_content?.tasks;
          _.forEach(messageTasks, (i, index) => {
            if (_.isEmpty(i.value)) {
              isMessage = true;
            }
          });
        }
      });
      if (isMessage) {
        toast.error("请填写短信通知内容！");
        return false;
      }
    }
    const data = {
      data: {
        ...paramsData,
        status: type,
      },
      id: id,
    };
    if (pageType === "add" && id) submitType = "edit";
    else if (pageType === "copy") {
      data.id = "";
      submitType = "add";
    } else if (pageType === "copy_template") {
      data.id = "";
      submitType = "add";
      data.data.is_template = false;
    }
    // debugger;
    let res = null;
    switch (submitType) {
      //新增
      case "add":
        res = await postMarketingPlans(data);
        break;
      case "copy" || "copy_template":
        data.id = "";
        res = await postMarketingPlans(data);
        break;
      //修改
      case "edit" || "info":
        res = await putMarketingPlans(data);
        break;
    }
    if (res?.code == 200) {
      if (type === "draft") {
        id = res.data?.plan_id;
        toast.success("保存草稿成功！");
      } else {
        toast.success("发布成功！");
        onHandleCallBack();
      }
    } else {
      toast.error(" 发布失败 错误信息:" + res?.message);
      return false;
    }
  };
  //启动计划
  const handleLaunchPlanChildValue = (value: any) => {
    let triggerTime = null;
    const { type } = marketing;
    let isNull = false;
    //如果是类型为单次营销或客户行为触发时，触发频率值为null
    if (type && (type === "once" || type === "customer_behavior")) {
      isNull = true;
    }
    if (type && value.type === undefined) {
      //当类型为客户行为触发时，触发时间值为null
      if (type === "customer_behavior") triggerTime = null;
      else
        triggerTime =
          type === "repeat" ? value.trigger_cron : value.trigger_time;
    } else
      triggerTime =
        value.type === "repeat" ? value.trigger_cron : value.trigger_time;
    marketing = {
      ...marketing,
      effective_time: value.effective_time === "" ? null : value.effective_time,
      expire_time: value.expire_time === "" ? null : value.expire_time,
      trigger_time: triggerTime,
      plan_is_forever: value.plan_is_forever,
      limit_type: value.limit_type,
      limit_count_period: value.limit_count_period,
      limit_count_period_unit: value.limit_count_period_unit,
      trigger_frequency: isNull ? null : value.trigger_frequency,
    };
  };
  //根据id获取计划详情
  const getMarketingInfoById = async (id: string) => {
    const res = await getMarketingPlansById(id);
    if (res.code === 200 && res.data) {
      const newObj: any = { ...marketing };
      for (const key in res.data) {
        if (key in marketing) {
          newObj[key] = res.data[key];
        }
      }
      // setInputPlan(res.data.name);
      setMarketing(newObj);
      setItems(newObj.trigger_rules);
      res.data?.tasks?.forEach((item: any) => {
        const taskIndex = tasks.findIndex(
          (task: any) => task.id === item?.plan_task_id
        );
        if (taskIndex !== -1) {
          tasks[taskIndex].taskContent = item?.exec_content;
        }
      });
      setTasks([
        ...tasks,
        ...res.data?.tasks?.map((item: any, index: number) => ({
          index: index + 3,
          id: item?.plan_task_id,
          type: item?.task_type,
          taskContent: {
            exec_type: item?.exec_type, // 执行类型，immediately：上一任务完成后立即执行，delay：延时
            task_name: item?.task_name,
            task_type: item?.task_type, // 类型，benefits：发送权益，message：发送消息，follow_up_task：回访任务
            delay_exec_period: item?.delay_exec_period,
            delay_exec_period_unit: item?.delay_exec_period_unit,
            delay_exec_time: item?.delay_exec_time,
            exec_content: item?.exec_content,
          },
        })),
      ]);
    }
  };

  //更新任务列表
  const updateTask = (index: number, newTask: any) => {
    const newItem = tasks?.map((task: any) => {
      if (task.index === index) {
        return {
          ...task,
          task_name: newTask.task_name,
          exec_type: newTask.exec_type,
          taskContent: newTask.taskContent,
        };
      }
      return task;
    });

    setTasks(newItem);
  };
  //更新商品列表
  const handleItemSave = (e: any) => {
    const newItems = [];
    if (e?.length > 0) {
      for (const i of e) {
        const items = {
          type: i.value,
          is_match_all: true,
          items: [],
        };
        newItems.push(items);
      }
    }
    setItems({ rules: newItems });
  };

  useEffect(() => {
    if (id) {
      getMarketingInfoById(id);
    }
  }, [id]);
  useEffect(() => {
    updateParamsData({ ["trigger_rules"]: items });
  }, [items]);
  useEffect(() => {
    const taskContents = tasks
      .filter((task: any) => Object.keys(task.taskContent)?.length !== 0)
      ?.map((task: any) => task.taskContent);
    updateParamsData({ ["tasks"]: taskContents });
  }, [tasks]);
  //启动任务
  const onHandleSubmit = {
    pageType: pageType,
    isDisabled: isDisabled,
    marketing: marketing,
    setCurTaskItem: updateTask,
    setType: (e: any) => {
      updateParamsData({ ["type"]: e });
    },
    setMarketing: updateParamsData,
    handleItemSave: handleItemSave,
    onChildValue: handleLaunchPlanChildValue,
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>创建营销活动</title>
        </MetaTags>
        <Container fluid>
          <div className="row">
            <Card className="addCustomScheduleCard body-card">
              <CardBody>
                <Row className="page-header row-box">
                  <div className="col-lg-1">
                    <button
                      type="button"
                      className="btn btn-outline-light waves-effect btn-go-back"
                      onClick={onHandleCallBack}
                    >
                      返回
                    </button>
                  </div>
                  <div className="col-lg-9">
                    <div className="div-title">
                      {isEdit ? (
                        <AvForm className="needs-validation equity-from">
                          <FormGroup className="mb-3">
                            <AvField
                              name="inputPlan"
                              placeholder="自定义营销计划"
                              type="text"
                              errorMessage="请输入自定义营销计划"
                              className="form-control inputPlan"
                              value={marketing.name}
                              disabled={isDisabled}
                              id="validationTaskName"
                              onChange={(e: any) => {
                                updateParamsData({ ["name"]: e.target.value });
                              }}
                            />
                          </FormGroup>
                        </AvForm>
                      ) : (
                        <h3>{marketing.name} </h3>
                      )}
                      <i
                        className="bx bx-edit-alt"
                        onClick={() => {
                          isDisabled === false ? setIsEdit(!isEdit) : null;
                        }}
                      ></i>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="div-tool-btn">
                      {pageType === "info" &&
                      marketing.type !== "once" &&
                      marketing.status !== "finished" ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-primary waves-effect"
                            onClick={() => {
                              setIsDisabled(false);
                              setPageType("edit");
                            }}
                          >
                            编辑
                          </button>
                        </>
                      ) : null}
                      {pageType === "edit" ? (
                        <button
                          type="button"
                          className="btn btn-primary waves-effect btn-release"
                          onClick={() => {
                            handleRelease("started");
                          }}
                        >
                          发布
                        </button>
                      ) : null}
                      {pageType === "copy" ||
                      pageType === "copy_template" ||
                      pageType === "add" ? (
                        <>
                          <button
                            type="button"
                            className="btn btn-outline-light waves-effect btn-save-draft"
                            onClick={() => {
                              handleRelease("draft");
                            }}
                          >
                            保存草稿
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary waves-effect btn-release"
                            onClick={() => {
                              handleRelease("started");
                            }}
                          >
                            发布
                          </button>
                        </>
                      ) : null}
                    </div>
                  </div>
                </Row>
                <Card className="plan-card">
                  <CardBody>
                    <Row className="launch-plan-row">
                      <Col>
                        {/* 启动计划 */}
                        <div
                          className={[
                            "plan",
                            step === "startPlan"
                              ? "plan-active"
                              : "plan-default",
                          ].join(" ")}
                          onClick={() => {
                            onHandlePlanClick(step);
                            setStep("startPlan");
                          }}
                        >
                          启动计划
                        </div>
                        <AddTaskCom
                          {...addTaskComProps}
                          id="planStart"
                        ></AddTaskCom>
                        {tasks?.map((e: any, index: any) => {
                          return (
                            <div key={index}>
                              {/* 发放权益 */}
                              {e.type === "benefits" ? (
                                <>
                                  {" "}
                                  <IssuanceOfEquityStep
                                    activeStep={step}
                                    id={e.id}
                                    onHandlePlanClick={(id: string) => {
                                      onHandlePlanClick("benefits", id);
                                      setStep(e.id);
                                    }}
                                    onHandleRemove={(id: string) => {
                                      onHandleRemove(id);
                                    }}
                                  ></IssuanceOfEquityStep>{" "}
                                  <AddTaskCom
                                    {...addTaskComProps}
                                    id={e.id}
                                  ></AddTaskCom>
                                </>
                              ) : (
                                ""
                              )}
                              {/* 发送消息 */}
                              {e.type === "message" ? (
                                <>
                                  {" "}
                                  <SendMessageStep
                                    activeStep={step}
                                    id={e.id}
                                    onHandlePlanClick={(id: string) => {
                                      onHandlePlanClick("message", id);
                                      setStep(e.id);
                                    }}
                                    onHandleRemove={(id: string) => {
                                      onHandleRemove(id);
                                    }}
                                  ></SendMessageStep>
                                  <AddTaskCom
                                    {...addTaskComProps}
                                    id={e.id}
                                  ></AddTaskCom>
                                </>
                              ) : (
                                ""
                              )}
                              {/* 回访任务 */}
                              {/* {e.type === "follow_up_task" ? (
                                <>
                                  {" "}
                                  <ReturnVisitTaskStep
                                    activeStep={step}
                                    id={e.id}
                                    onHandlePlanClick={()=>{
                                      onHandlePlanClick('follow_up_task');
                                      setStep(e.id);
                                    }}
                                    onHandleRemove={(id: string) => {
                                      onHandleRemove(id);
                                    }}
                                  ></ReturnVisitTaskStep>
                                  <AddTaskCom
                                    {...addTaskComProps}
                                    id="addTask_follow_up_task"
                                  ></AddTaskCom>
                                </>
                              ) : (
                                ""
                              )} */}
                            </div>
                          );
                        })}
                        {/* 终止计划 */}
                        <div style={{ marginRight: "20px" }}>
                          <div
                            className={[
                              "plan",
                              step === "endPlan"
                                ? "plan-active"
                                : "plan-default",
                            ].join(" ")}
                            onClick={() => {
                              onHandlePlanClick(step), setStep("endPlan");
                            }}
                          >
                            终止计划
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {/* 启动计划-内容体 */}
                {step === "startPlan" ? (
                  <LaunchPlan {...onHandleSubmit}></LaunchPlan>
                ) : null}
                {tasks?.map((item: any, index: any) => {
                  return (
                    <div id={item.id} key={index}>
                      {/* 发放权益-内容体 */}
                      {item.type === "benefits" && step === item.id ? (
                        <IssuanceOfEquity
                          {...onHandleSubmit}
                          curItem={item}
                        ></IssuanceOfEquity>
                      ) : null}
                      {/* 发送消息-内容体 */}
                      {item.type === "message" && step === item.id ? (
                        <SendMessage
                          {...onHandleSubmit}
                          curItem={item}
                        ></SendMessage>
                      ) : null}
                      {/* 回访任务-内容体 */}
                      {/* {item.type === "follow_up_task" && step === item.id ? (
                        <ReturnVisitTask
                          curItem={item}
                          handleIssuanceOfEquityChange={
                            handleIssuanceOfEquityChange
                          }
                        ></ReturnVisitTask>
                      ) : null} */}
                    </div>
                  );
                })}
                {/* 终止计划-内容体 */}
                {step === "endPlan" ? (
                  <div style={{ margin: "20px" }}>
                    <Row style={{ height: "100px" }}>
                      <Label
                        htmlFor="validationTaskName"
                        style={{ marginRight: "20px" }}
                      >
                        <span style={{ color: "#e5001d" }}>
                          &nbsp;&nbsp;&nbsp;*{" "}
                        </span>
                        终止计划设置：&nbsp;&nbsp;&nbsp;
                        当客户有一个任务执行失败
                      </Label>
                      <div className="termination-plan-div">
                        <Row style={{ margin: "5px 0 10px -10px" }}>
                          <Col>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="termination-plan"
                              disabled={isDisabled}
                              defaultChecked
                              value="terminate"
                              checked={
                                marketing.plan_termination_type === "terminate"
                              }
                              onChange={(e) => {
                                updateParamsData({
                                  ["plan_termination_type"]: e.target.value,
                                });
                              }}
                            />
                            <Label className="label-1">
                              &nbsp;&nbsp;直接针对客户终止计划
                            </Label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <input
                              className="form-check-input"
                              type="radio"
                              name="termination-plan"
                              disabled={isDisabled}
                              value="skip"
                              checked={
                                marketing.plan_termination_type === "skip"
                              }
                              onChange={(e) => {
                                updateParamsData({
                                  ["plan_termination_type"]: e.target.value,
                                });
                              }}
                            />
                            <Label className="label-1">
                              &nbsp;&nbsp;该客户跳到下一个任务继续执行
                            </Label>
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  </div>
                ) : null}
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddCustomSchedule;
