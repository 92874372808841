import {
  Button,
  ConfigProvider,
  Input,
  Pagination,
  PaginationProps,
  Space,
  Table,
} from "antd";
import { ColumnsType } from "antd/es/table";
import locale from "antd/locale/zh_CN";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Modal, Row, Tooltip } from "reactstrap";
import { getMarketingPlans } from "../../../../helpers/mallbackend_helper";
import { SearchOutlined } from "@ant-design/icons";

const SearchCom = (props: any) => {
  const { toggle, isOpen, onOk } = props;
  const [open, setOpen] = useState(false);
  const [marketingList, setMarketingList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyWord] = useState(""); //关键词搜索

  const pageOptions: any = {
    pageSize: pageSize, // ，每页条数
    pageNumber: pageNumber, //页码
    total: total, //总数
    custom: true,
  };
  const showTotal: PaginationProps["showTotal"] = (total: number) =>
    `每页 ${pageOptions.pageSize} 条，共 ${total} 条`;

  //获取营销计划列表
  const getMarketing = async (page?: Number) => {
    let apiFilter = {
      page_size: pageSize,
      page_number: page || pageNumber,
      type: null,
      keyword,
      status: null,
    };
    const res = await getMarketingPlans(apiFilter);
    if (res.code === 200) {
      setMarketingList(res.data);
      setTotal(res.total);
    }
  };

  //搜索
  const handleSearch = () => {
    getMarketing();
  };
  //分页更改
  const onPageChange = (pageSize: number, pageNumber: number) => {
    getMarketing(pageSize);
  };
  const handleChoose = (plan_id: string, name: string) => {
    onOk(plan_id, name);
  };
  const handleRowDoubleClick = (record: any, key: any) => {
    onOk(record.plan_id, record.name);
    key.stopPropagation(); //阻止默认事件
  };
  useEffect(() => {
    setOpen(isOpen);
    if (isOpen) {
      getMarketing();
    }
  }, [isOpen]);

  interface DataType {
    plan_id: string;
    name: string;
    type_cn: string;
    trigger_time: string;
    target_is_all_customer_cn: string;
    status_cn: string;
    created_at: string;
  }
  const dateTime = "YYYY-MM-DD HH:mm:ss";
  const modalOpts = {
    size: "xl", //ml,sm,lg,xl
    role: "dialog",
    isOpen: open,
    centered: true,
    autoFocus: true,
    toggle: toggle,
    className: "add-coupon-modal task-history-modal",
  };
  const columns: ColumnsType<DataType> = [
    {
      title: "活动名称",
      dataIndex: "name",
      key: "name",
      width: 250,
      render: (text) => <a>{text}</a>,
    },
    {
      title: "计划类型",
      dataIndex: "type_cn",
      key: "type_cn",
      width: 200,
    },
    {
      title: "活动时间",
      dataIndex: "trigger_time",
      key: "trigger_time",
      width: 250,
      render: (time, item: any) => {
        if (item?.plan_is_forever) {
          return "长期有效";
        } else if (item?.effective_time && item?.expire_time) {
          return `${moment(item?.effective_time).format(dateTime)} - ${moment(
            item?.expire_time
          ).format(dateTime)}`;
        } else {
          return "-";
        }
      },
    },
    {
      title: "覆盖客户",
      key: "target_is_all_customer_cn",
      dataIndex: "target_is_all_customer_cn",
      width: 250,
    },
    {
      title: "更新时间",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 250,
      render: (text) => {
        return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "";
      },
    },
    {
      title: "操作",
      key: "action",
      width: 100,
      render: (item) => (
        <Space size="middle">
          <Button
            style={{ width: "40px", margin: "0 0px 0 -20px" }}
            type="link"
            onClick={(e) => {
              handleChoose(item.plan_id, item.name);
            }}
          >
            选择
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Modal backdrop="static" {...modalOpts} toggle={toggle}>
        <div className="modal-header">
          请选择要查看的营销计划任务历史
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body ">
          <div className="container-fluid dataTableCom">
            <Row>
              <Col lg={3} style={{ top: "-9px" }}>
                <div className="search-box position-relative">
                  <Input
                    className="div-search-box"
                    size="large"
                    placeholder="请输入活动名称"
                    prefix={<SearchOutlined />}
                    onChange={(e) => {
                      setKeyWord(e.target.value);
                    }}
                    onPressEnter={handleSearch}
                  />
                </div>
              </Col>
              <Col lg={9} style={{ top: "-2px" }}>
                <div className="activity-box">
                  <button
                    type="button"
                    className="btn btn-outline-light waves-effect btnSearch"
                    onClick={handleSearch}
                  >
                    搜索
                  </button>
                </div>
              </Col>
            </Row>

            <ConfigProvider locale={locale}>
              <Table
                columns={columns}
                dataSource={marketingList}
                rowKey={(record) => record.plan_id}
                pagination={false}
                onRow={(record, rowkey) => {
                  return {
                    onDoubleClick: (e) => {
                      handleRowDoubleClick(record, e);
                    },
                  };
                }}
              />
              <Pagination
                showQuickJumper
                defaultPageSize={pageOptions.pageSize}
                showTotal={showTotal}
                total={pageOptions.total}
                onChange={onPageChange}
                defaultCurrent={1}
              />
            </ConfigProvider>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-light waves-effect btn-cancel"
            onClick={toggle}
          >
            关闭
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};
export default SearchCom;
