import React, { useContext, useEffect, useRef, useState } from "react";
import { InputNumber, InputRef } from "antd";
import { Button, Form, Input, Popconfirm, Table } from "antd";
import type { FormInstance } from "antd/es/form";
import moment from "moment";
import _ from "lodash";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  batch_id: string;
  count: number;
  residue_stock: number;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: Item;
  record: Item;
  handleSave: (record: Item) => void;
}

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  name: string;
  count: number;
  limitValue: string;
  residue_stock: string;
  batch_id: string;
}

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const EditTableCom = (props: any) => {
  const { dataList, setDataList } = props;
  const [dataSource, setDataSource] = useState<DataType[]>([]);

  useEffect(() => {
    if (dataList) {
      setDataSource(dataList);
    }
  }, [dataList]);

  const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext);

    useEffect(() => {
      if (editing) {
        inputRef.current?.focus();
      }
    }, [editing]);

    const toggleEdit = () => {
      setEditing(!editing);
      form?.setFieldsValue({ count: record.count });
    };

    const save = async () => {
      try {
        const values = await form?.validateFields();
        toggleEdit();
        _.forEach(dataList, (item, index) => {
          if (item.batch_id == record?.batch_id) {
            item.count = values?.count;
          }
        });
        setDataList(dataList);
        handleSave({ ...record, ...values });
      } catch (errInfo) {
        console.log("Save failed:", errInfo);
      }
    };

    let childNode = children;
    //ref={inputRef}
    if (editable) {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name="count"
          rules={[
            {
              required: true,
              message: `${title}不能为空.`,
            },
          ]}
        >
          <InputNumber
            min={1}
            max={record?.residue_stock}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{
            border: " 1px solid #cccccc8a",
            padding: "3px 10px",
            borderRadius: "5px",
            width: 90,
          }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }

    return <td {...restProps}>{childNode}</td>;
  };
  //删除
  const handleDelete = (row: any) => {
    const newData = dataSource.filter(
      (item: any) => item.batch_id !== row.batch_id
    );
    setDataSource(newData);
    setDataList(newData);
  };
  
  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
      width: 300,
    },
    {
      title: "面额",
      dataIndex: "limitValue",
      key: "limitValue",
      width: 100,
    },
    {
      title: "库存",
      dataIndex: "residue_stock",
      key: "residue_stock",
      width: 100,
      render: (count: number) => {
        if (count > 0) {
          return (
            <span style={{ color: "green", fontWeight: "bold" }}>{count}</span>
          );
        } else
          return (
            <span style={{ color: "red", fontWeight: "bold" }}>{count}</span>
          );
      },
    },
    {
      title: "赠送卡券数量",
      dataIndex: "count",
      width: 200,
      editable: true,
    },
    {
      title: "可用商场",
      key: "couponmallcategory",
      dataIndex: "couponmallcategory",
      width: 200,
      render: (value) => {
        if (value?.length > 0) {
          return value
            ?.map((item: { category: { name: any } }) => item.category.name)
            ?.join(",");
        }
      },
    },
    {
      title: "有效开始日期",
      dataIndex: "valid_begin_at",
      key: "valid_begin_at",
      width: 250,
      render: (text) => {
        return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "";
      },
    },
    {
      title: "有效结束日期",
      dataIndex: "valid_end_at",
      key: "valid_end_at",
      width: 250,
      render: (text) => {
        return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : "";
      },
    },
    {
      title: "限制要求",
      dataIndex: "limit_content",
      key: "limit_content",
      width: 150,
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 150,
      render: (_, record: any) =>
        dataSource.length >= 1 ? (
          <Button
            type="link"
            onClick={() => {
              handleDelete(record);
            }}
            style={{ color: "#8558fa" }}
          >
            删除
          </Button>
        ) : null,
    },
  ];

  const handleSave = (row: DataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns: any = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <div>
      <Table
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource}
        columns={columns}
        style={{ height: "300px" }}
        rowKey={(record) => record.batch_id}
      />
    </div>
  );
};

export default EditTableCom;
